import React from "react";


const Button = (props)=>{
    return (
        <div className="container clearfix">
            <div className="row">
        <button className="btn btn-primary" onClick={props.onClick}>{props.value}</button>
        </div>
        </div>
    );
};

export default Button;