
import logo from './logo.svg';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { PageLoader } from "./Components/page-loader";
import { AuthenticationGuard } from "./Components/authentication-guard";
import Home from './Components/Home';
import About from './Components/About';
import Navbar from './Components/Navbar';
import { BrowserRouter as Router } from 'react-router-dom';
import Faq from './Components/Faq';
import Contact from './Components/Contact';
import Register from './Components/Register';
import Thanks from './Components/Thanks';
import Account from './Components/Account';
import Logout from './Components/Logout';
import Login from './Components/Login';
import UpdateUser from './Components/UpdateUser';
import Shop from './Components/Shop';
import Listing from './Components/Listing';
import Details from './Components/Details';
import Game from './Components/Game.tsx';
import HomePage from './Pages/Home/index.jsx';
import RoomPage from './Pages/Room/index.jsx';
import LiveRoom from './Pages/Livestreaming/room.jsx';
import HomeLiveRoom from './Pages/Livestreaming/index.jsx';
import Weather from './Components/Weather.tsx';
import { messaging } from './firebase.js';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
// import { AdminPage } from "./Pages/admin-page";
// import { CallbackPage } from "./Pages/callback-page";
// import { NotFoundPage } from "./Pages/not-found-page";
// import { ProfilePage } from "./Pages/profile-page";
// import { ProtectedPage } from "./Pages/protected-page";
// import { PublicPage } from "./Pages/public-page";

function App() {

  const { isLoading } = useAuth0();

  
  
  async function requestPermission(){
    
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
         getToken(messaging, {vapidKey: 'BDLrVRqBoTMWcgIv2l9fLBt2NMY4JAYuVix59SEhw-klLfWu1eqeeGMUS9hMMyVnxP6M6X9x7teKsGr9VbBD-Wg'}).then((currentToken)=>{
          console.log("tokeen", currentToken);
          const messaging = getMessaging();
        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);
          const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: payload.notification.image,
  };
  console.log(notificationTitle, notificationOptions);
  //self.registration.showNotification(notificationTitle, notificationOptions);
        });
        });
        //console.log('token',token);
      }else if(permission === 'denied'){
        alert("You denied for the notification!");
      }

      

    });

    
    
  }
  
  
  useEffect(()=>{
    // request user for notification permission 
    requestPermission();
  }, []);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  
  /* return (
    <div className="App">
      <About text="This is the About probs from app component" name={{ data:'Komal' }} address={{data:'HN-3457 Gurugram-122007'}}/>
     
      <Home text="This is the Home probs from app component" name={{ data:'Home Komal' }} address={{data:'Home HN-3457 Gurugram-122007'}}/>
      <h1>Welcome to React</h1>
      <h2>{text}</h2>
      <button>Simple Button</button>
      <Button>Bootstrap Button</Button>
      <Button onClick={handleClick}>
      You pressed me {count} times
    </Button>
    <h1>User List</h1>
    <ul>{user.name}
      {user && user.length > 0 && user.map((userObj,index)=>
        <li key={userObj.id}>{userObj.name} {userObj.email}</li>
      )}
    </ul>
    </div>
    
  ); */
  return(
    <>
    
   
  <Router>
  <Navbar/>
    <Routes>
      <Route exact path='/' element={<Home/>} ></Route>
      <Route exact path='/about' element={<About/>} ></Route>
      <Route exact path='/contact' element={<Contact/>} ></Route>
      <Route exact path='/faq' element={<Faq/>} ></Route>
      <Route exact path='/register' element={<Register/>} ></Route>
      <Route exact path='/thanks' element={<Thanks/>} ></Route>
      <Route exact path='/login' element={<Login/>} ></Route>
      <Route exact path='/account' element={<Account/>} ></Route>
      <Route exact path='/logout' element={<Logout/>} ></Route>
      <Route exact path='/update-user' element={<UpdateUser/>} ></Route>
      <Route exact path='/shop' element={<Shop/>} ></Route>
      <Route exact path='/listing' element={<Listing/>} ></Route>
      <Route exact path='/details' element={<Details/>} ></Route>
      <Route exact path='/game' element={<Game/>} ></Route>
      <Route exact path='/homepage' element={<HomePage/>} ></Route>
      <Route exact path='/room/:roomId' element={<RoomPage/>} ></Route>
      <Route exact path='/liveroom/:liveRoomId' element={<LiveRoom/>} ></Route>
      <Route exact path='/homeliveroom' element={<HomeLiveRoom/>} ></Route>
      <Route exact path='/wheather' element={<Weather/>} ></Route>
      
    </Routes>
  </Router>
  </>
  )
}

export default App;
