import React,{useEffect, useState} from 'react';
import { useNavigate } from 'react-router';

export const Logout = () => {
    const navigate = useNavigate();
    const logout = ()=>{
        localStorage.clear();
        navigate('/login');

    }
    useEffect(()=>{
        logout();
    });
  return (
    <div>Logout</div>
  )
}
export default Logout
