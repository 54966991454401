import React, {useState, useCallback} from "react";
import { useNavigate } from "react-router-dom";

const HomePage = ()=>{
    const [value, setValue] = useState("");
    const navigage = useNavigate();
    const handleJoinRoom = useCallback(()=>{
        navigage(`/room/${value}`);
    },[navigage, value]);
    return <div className="container">
         <div className="reg-wrapper">
        <h1 className="account-h2 u-s-m-b-20">Room Code</h1>
        <input type="text" value={value} onChange={(e) =>
                                                setValue(e.target.value)
                                            } className="form-control input" id="name" placeholder="Enter room code" />
                                            <label htmlFor="name">Room Code</label>

<div className="col-12 mt-2">
        <button onClick={handleJoinRoom} className="btn btn-primary">Join</button>
    </div>
    </div>
    </div>
}

export default HomePage;