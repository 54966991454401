import React from "react";
import { useParams } from "react-router-dom";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";


const RoomPage = ()=>{
    const { roomId } = useParams();

    const myMeeting = async (element)=>{
        const appID = 766680428;
        const serverSecret = "ba8cbba74ee41009ebac07cabf218d35";
        const roomID = roomId;
        const userID = Math.floor(Math.random() * 10000) + "";
        const userName = "userName" + userID;
        const ExpirationSeconds = "567686868";
        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userID, userName);
        const zc = ZegoUIKitPrebuilt.create(kitToken);
        zc.joinRoom({
            container: element,
            sharedLinks: [
                {
                    name: 'Copy Link',
                    url: `https://bookappointments.in/room/${roomId}`
            }
        ],
            scenario: {
                mode: ZegoUIKitPrebuilt.OneONoneCall,
            },
            showScreenSharingButton: true,
        })

    }
    return <div className="container">
                
                <div ref={myMeeting} />
            </div>
}

export default RoomPage;