import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { $ } from 'react-jquery-plugin';

export const Login = () => {
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const nagigate = useNavigate();
    const [validated, setValidated] = useState(false);//alert(validated);
    
    async function handleSubmit(e) {
        e.preventDefault();
        let error = false;
        $(".input").each(function(t,i){
            let elementId = $(i).attr('id');
            if($("#"+elementId).val() == ''){console.log(elementId);
             error=true;
             $("#"+elementId+"-error").html('Please enter your '+ elementId);
             $("#"+elementId+"-error").attr('style','color:red');
             $("#"+elementId+"-error").removeClass('d-none');
             $("#"+elementId+"-error").show();
             //return;
            }else if(elementId == 'email' && $("#"+elementId).val() != ''){
             let emailExp = new RegExp(/^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i);
             console.log('emailExp',emailExp.test($("#"+elementId).val()));
             if(!emailExp.test($("#"+elementId).val())){
                 error=true;
                 $("#"+elementId+"-error").attr('style','color:red');
                 $("#"+elementId+"-error").removeClass('d-none');
                 $("#"+elementId+"-error").html('Please enter your valid '+ elementId);
                 $("#"+elementId+"-error").show();
             }else{
                 $("#"+elementId+"-error").hide();
             }
            }else{
             $("#"+elementId+"-error").hide();
             
            }
             
             //console.log($(t.attr('id')));
         });
         if(!error){
            console.log('no any error');
            let data ={
                email,password
            }
           // console.log(data);
            let result = await fetch("http://127.0.0.1:8000/api/login-user",{
                method:"post",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify(data)
            });
            let response = await result.json();
            console.log(response);
            if(response.success!=false){
                localStorage.setItem('user',JSON.stringify(response));
                localStorage.setItem('token',response.token);
                nagigate("/account");
            }else{
                error=true;
                //console.log(response.errors.email);
                $.each(response.errors,function(i,error){
                    console.log(i);
                    $("#"+i+"-error").removeClass('d-none');
                    $("#"+i+"-error").attr('style','color:red');
                    $("#"+i+"-error").html(error);
                });
            
            }
            
        }
    }
  return  (
    <div className="container">
    <div className="reg-wrapper">
        <h2 className="account-h2 u-s-m-b-20">Login</h2>
        
        <h6 className="account-h6 u-s-m-b-30">Welcome back! Sign in to your account.</h6>
        <p id="register-message"></p>
        <form name='register'  id='register' method='post' onSubmit={handleSubmit}>
        
        <div className="form-floating mb-3">
        <input type="email" value={email} onChange={(e) =>
                                                setEmail( e.target.value)
                                            } className="form-control input" id="email" placeholder="name@example.com" />
        <label htmlFor="email">Email address</label>
        <p id='email-error' className='ms-3 d-none'>error</p>
        </div>
        
    <div className="form-floating">
        <input type="password" value={password} onChange={(e) =>
                                                setPassword(e.target.value)
                                            } className="form-control input" id="password" placeholder="Password" />
        <label htmlFor="password">Password</label>
        <p id='password-error' className='ms-3 d-none'>error</p>
    </div>
    <div className="col-12 mt-2">
        <button type="submit" className="btn btn-primary">Login</button>
    </div>
    </form>
    </div>
  </div>
  )
}
export default Login
