import React, { useState } from "react";
import Block from "./Block.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./page-loader.js";


function Game(){

    const { isLoading, user, loginWithRedirect } = useAuth0();
    //console.log(user);
    
    const Results = (prop) => (
        <div className="container">
        <div id="results" className="search-results">
          { prop.value===0?'X':0 } won the game.
          
        </div>
        </div>
      )
    const [state, setState] = useState(Array(9).fill(null));
    const [currentTurn, setCurrentTurn] = useState("X");
    const [showResults, setShowResults] = useState(false);

    if (isLoading) {
        return (
          <div className="page-layout">
            <PageLoader />
          </div>
        );
      }
      if (!user) {
      loginWithRedirect();
        return null;
      }
    const checkWinner = (state: any[])=>{
        const win = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6],
        ];
        for(let i=0; i < win.length; i++){
            const [a, b, c] = win[i];
            if(state[a] !== null && state[a] === state[b] && state[a] === state[c]){
                return true;
            }
        }
    }
    
    const handleBlockClick = (index: Number)=>{
        const stateCopy = Array.from(state);
        if(!state.some((e)=> e===null)){
            alert('Draw the game!');
            window.location.reload(true);
        }
        if(stateCopy[index] !== null){
            
             return;
        }
        if(showResults) {
            
            return;
        }
        stateCopy[index] = currentTurn;
        //Check if someone won
        const win = checkWinner(stateCopy);
        setCurrentTurn(currentTurn==="X"?0:"X");
        setState(stateCopy);
        
       
        setTimeout(() => {

            if(win){
                setShowResults(true);
                setTimeout(()=>{
                    window.location.reload(true);
                }, 3000);
                //alert(`${currentTurn} won the game.`)
                //document.write(`${currentTurn} won the game.`);
            }
            

        }, 0);
        
        
    };
    console.log("State",state);
    return (
        <>
        <div className="container">
        
            <div className="grid">
            <div className="game-row">
            <Block onClick={()=>handleBlockClick(0)} value={state[0]} />
            <Block onClick={()=>handleBlockClick(1)} value={state[1]} />
            <Block onClick={()=>handleBlockClick(2)} value={state[2]} />

            </div>
            <div className="game-row">
            <Block onClick={()=>handleBlockClick(3)} value={state[3]} />
            <Block onClick={()=>handleBlockClick(4)} value={state[4]} />
            <Block onClick={()=>handleBlockClick(5)} value={state[5]} />
                
            </div>
            <div className="game-row">
            <Block onClick={()=>handleBlockClick(6)} value={state[6]} />
            <Block onClick={()=>handleBlockClick(7)} value={state[7]} />
            <Block onClick={()=>handleBlockClick(8)} value={state[8]} />
                
            </div>
            </div>
        </div>
        { showResults ? <Results value={currentTurn} /> : null }
        </>
    )
}

export default Game;