import React from 'react'

export const Thanks = () => {
  return (
    <div className="container">
    <div className="reg-wrapper">
    <div className="alert alert-success" role="alert">
        Thank you for registration! You will get login details via your registerd email!
    </div>
    </div>
    </div>
  )
}
export default Thanks