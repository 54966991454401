import React from "react";
import { useWheather } from "../context/WheatherContext";


const Input = ()=>{

    const wheather = useWheather();
    console.log("Wheather ", wheather);
    return (
        <div className="container clearfix">
            <div className="row">
        <input className="form-control" placeholder="Search here.." value={wheather.searchCity} onChange={(e)=>wheather.setSearchCity(e.target.value)} />
        </div>
        </div>
    );
};

export default Input;