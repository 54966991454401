import React,{ Component, useEffect, useState } from "react";
//import Config from "./Config";
import { Config } from "./Config";
import axios from 'axios';
const client = axios.create({
    baseURL: "http://127.0.0.1:8000/api/"
  }); 
  
function About(){
   // const RawHTML = ({children, className = ""}) =><div className={className}dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}} />
    let base_path = Config.base_path;
    let [page,setPage] = useState([]);
    let [config,setConfig] = useState(Config.base_path);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = useState(true);
    async function getAboutus() {
        const response = await client.get("about-us");
        setPage(response.data.cmsDetails);
      }
/*     const fetchData = () => {
        return axios.get('http://127.0.0.1:8000/api/about-us')
        .then((response)=>{
            
            setPage(response.data.cmsDetails)}).catch(error => {
                setError(error);
              });
    } */
    //    useEffect(()=>{
    //     //fetchData();
    //     getAboutus();
    //    },[]);   

    useEffect(() => {
        const fetchData = async () =>{
          setLoading(true);
          try {
            const response = await client.get("about-us");
            setPage(response.data.cmsDetails);
            //alert(response);
          } catch (error) {
            //console.error(error.message);
            setError(error)
          }
          setLoading(false);
        }
    
        fetchData();
      }, []);
       if (error) return (
                    <div className="container">
                        <h1>Error:</h1>
                        <p>{error.message}</p>
                    </div>
                ) 
        if (!page) return "No data!" 
        if (loading) return (
            <div className="container text-center pt-5">
            <div className=" spinner-border  text-danger" role="status"><span className="sr-only">Loading...</span>
            </div>
            </div>
                )        
    return (
        <div className="container">
            {loading && <div>Loading ...</div>}
            {!loading && (
                <div>
            <h1>About us page {page.cmsDetails}</h1>
            <p dangerouslySetInnerHTML={{ __html: page.description }}></p>
            </div>
            )}
        {Config.base_path
        /* <h1>{prop.text}</h1>
     <h1>{prop.name.data}</h1>
     <h1>{prop.address.data}</h1> */}
     </div>
    )
}
export default About;