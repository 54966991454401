import axios from 'axios';
import React,{ useEffect, useState, useRef} from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

export const Listing = () => {
    const [products,setProducts] = useState([]);
    const [searchParams,setSearchParams] = useSearchParams();
    //console.log(searchParams.get("url"))
    const fetchData = () =>{
        return axios.get("http://127.0.0.1:8000/api/listing/" + searchParams.get("url"))
        .then((response)=>{
            setProducts(response.data['products']);
        })
    }
    const productsRef = useRef(products);
   // console.log(searchParams.get("url"))
    useEffect(()=>{
        fetchData();
    },[productsRef]);
   // console.log(products);
  return (
      <div className="container">
    <div className="row g-4 py-5">
        { products.map((productObj)=>{
            return <div className="col-md-3" key={productObj.id}>
        <div className="product-single-card">
          <div className="product-top-area">
            <div className="product-discount">
            <span className="a-price-symbol">&#8377;</span>{productObj.product_discount?" "+productObj.product_price*productObj.product_discount/100:'0'}
            </div>

            <div className="product-img">
              <div className="first-view">
              <Link to={"/details?id="+productObj.product_slug}><img src={productObj.product_image} alt="logo" className="img-fluid"  /></Link>
              </div>
              <div className="hover-view">
              <Link to={"/details?id="+productObj.product_slug}><img src={productObj.product_image} alt="logo" className="img-fluid"  /></Link>
              </div>
            </div>
            <div className="sideicons">
              <button className="sideicons-btn">
                <i className="fa-solid fa-cart-plus"></i>
              </button>
              <button className="sideicons-btn">
                <i className="fa-solid fa-eye"></i>
              </button>
              <button className="sideicons-btn">
                <i className="fa-solid fa-heart"></i>
              </button>
              <button className="sideicons-btn">
                <i className="fa-solid fa-shuffle"></i>
              </button>
            </div>
          </div>
          <div className="product-info">
            <ul className="bread-crumb">
                <li className="has-separator">
                    {productObj.product_code}
                </li>
                <li className="has-separator">
                    {productObj.product_color}
                </li>
                <li>
                    {productObj.brand.name}
                </li>
            </ul>
            {/* <h6 className="product-category"><Link to="#">Gaming</Link></h6> */}
            <h6 className="product-title text-truncate"><Link to={"/details?id="+productObj.product_slug}>{productObj.product_name}</Link></h6>
            {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
            <div className="d-flex align-items-center">
              <div className="review-star me-1">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-regular fa-star-half-stroke"></i>
                <i className="fa-regular fa-star"></i>
              </div>

              <span className="review-count">(13)</span>
            </div>
            <div className="d-flex flex-wrap align-items-center py-2">
              <div className="old-price">
              <span className="a-price-symbol">&#8377;</span>{productObj.product_price}
              </div>
              <div className="new-price">
              <span className="a-price-symbol">&#8377;</span>{productObj.final_price}
              </div>

            </div>
          </div>
        </div>
      </div>
        })
      
      }
    </div>
  </div>
  )
}
export default Listing
