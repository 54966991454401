import { createContext, useContext, useState } from "react";
import { getWheatherDataFromCity, getWheatherDataFromLocation } from "../api";

const WheatherContext = createContext(null);

export const useWheather = ()=>{
    return useContext(WheatherContext);
}

export const WheatherProvider = (props)=>{
    const [data, setData] = useState(null);
    const [searchCity, setSearchCity] = useState(null);

    const fetchData =  async () => {
        const response = await getWheatherDataFromCity(searchCity);
        setData(response);
    }
    const getCurrentLocation = async () => {
        navigator.geolocation.getCurrentPosition((position)=>{
            getWheatherDataFromLocation(position.coords.latitude, position.coords.longitude).then((data)=>setData(data));
        });
    }
    const pageRefresh =  ()=>{
         window.location.reload(false);
    }
    return (
    <WheatherContext.Provider value={{ searchCity, data, setSearchCity, setData, fetchData, getCurrentLocation, pageRefresh }}>
        {props.children}
    </WheatherContext.Provider>
    );
};
