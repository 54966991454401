import React from 'react'

export const Account = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    return (
        <div className="container">
        <div className="reg-wrapper">
        <div className="alert alert-success" role="alert">
        { 
        localStorage.getItem('user')?
            <>
            Welcome {user.userDetails.name}! {user.message}.
            </>
            :null
        }
        </div>
        </div>
        </div>
      )
}
export default Account
