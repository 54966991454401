import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import React from "react";
import { useParams } from "react-router-dom";

const LiveRoom = ()=>{
    const {liveRoomId} = useParams();
    const myMeeting = async (element)=>{
        const appID = 664667213;
        const serverSecret = "10a9cd9ef03595015542103a8f5ddcf2";
        const roomID = liveRoomId;
        const userID = Math.floor(Math.random() * 10000) + "";
        const userName = "userName" + userID;
        const ExpirationSeconds = "567686868";
        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userID, userName);
        const zc = ZegoUIKitPrebuilt.create(kitToken);
        zc.joinRoom({
            container: element,
            sharedLinks: [
                {
                    name: 'Copy Link',
                    url: `https://bookappointments.in/liveroom/${liveRoomId}`
            }
        ],
            scenario: {
                mode: ZegoUIKitPrebuilt.VideoConference,
            },
            showScreenSharingButton: true,
        })

    }

    return <div className="container">
        <div ref={myMeeting} />
                </div>
}

export default LiveRoom