import { useAuth0 } from '@auth0/auth0-react';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';


const Navbar = () => {
    // when we add below line then header navigation working fine ... if user login then show Account and Logout navigation otherwise showing Login and register navigation
    const nagigate = useNavigate();
    const { user, loginWithRedirect, isAuthenticated, logout } = useAuth0();
  console.log(`Current user ${user}`,user);
  return (
    <>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end me-1">
        <span className='badge rounded-pill bg-dark align-middle ' style={{paddingTop: '13px'}}>{isAuthenticated && user.name}</span>
        {isAuthenticated?(<button className='btn btn-primary pull-right float-right' onClick={(e)=>logout()}>Logout</button>):(<button className='btn btn-primary' onClick={(e)=>loginWithRedirect()}>Login</button>)}</div>
<nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/"><Image src='/best.png' width="15%" alt="BEST" title='BEST'  fluid /></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">About Us</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact">Contact Us</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/faq">FAQ</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/shop">Shop</Link>
        </li>
        {

        //localStorage.getItem('user')?
        user?
        <>
        {/* <li className="nav-item">
          <Link className="nav-link " to="/account" >Account</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link " to="/update-user" >Update User Record</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link " to="/logout" >Logout</Link>
        </li> */}
        <li className="nav-item">
          <Link className="nav-link " to="/game" >Game</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link " to="/homepage" >Live Chat</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link " to="/homeliveroom" >Video Chat</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link " to="/wheather" >Weather Forecast</Link>
        </li>
        </>
        :
        <>
        {/* <li className="nav-item">
          <Link className="nav-link " to="/register" >Register</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link " to="/login" >Login</Link>
        </li> */}
        
        </>
        }
        
      </ul>
      
    </div>
    
  </div>
</nav>
</>
  )
}
export default Navbar
