import React from "react";
import { useWheather } from "../context/WheatherContext";

const Card = ()=>{
    const wheather = useWheather();
    return (
        <div className="container card">
            <div className="row">
            <img src={wheather?.data?.current?.condition?.icon} />
            <h2>{ wheather?.data?.current?.temp_c?wheather?.data?.current?.temp_c+' c':'' }</h2>
            <h5>{wheather?.data?.location?.name} {wheather?.data?.location?.region} {wheather?.data?.location?.country}</h5>
            </div>
        </div>
    );
};

export default Card;

