

const baseUrl = "https://api.weatherapi.com/v1/current.json?key=0274324967954ee3903121428241607";

export const getWheatherDataFromCity = async (city)=>{
    const response = await fetch(`${baseUrl}&q=${city}&aqi=yes`);
    return await response.json();
};

export const getWheatherDataFromLocation = async (lat, lan)=>{
    const response = await fetch(`${baseUrl}&q=${lat},${lan}&aqi=yes`);
    return await response.json();
};