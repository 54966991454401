import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { $ } from 'react-jquery-plugin';

export const Register = () => {
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [mobile,setMobile] = useState("");
    const [password,setPassword] = useState("");
    const nagigate = useNavigate();
    const [validated, setValidated] = useState(false);//alert(validated);
    
    async function handleSubmit(e) {
        e.preventDefault();
        let error = false;
        $(".input").each(function(t,i){
           // $(this).find(':input') //<-- Should return all input elements in that specific form.
           // console.log($(this).find(':input').attr('id'));
           //console.log($(i));
           let elementId = $(i).attr('id');
           if($("#"+elementId).val() == ''){console.log(elementId);
            error=true;
            $("#"+elementId+"-error").html('Please enter your '+ elementId);
            $("#"+elementId+"-error").attr('style','color:red');
            $("#"+elementId+"-error").removeClass('d-none');
            $("#"+elementId+"-error").show();
            //return;
           }else if(elementId == 'email' && $("#"+elementId).val() != ''){
            let emailExp = new RegExp(/^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i);
            console.log('emailExp',emailExp.test($("#"+elementId).val()));
            if(!emailExp.test($("#"+elementId).val())){
                error=true;
                $("#"+elementId+"-error").attr('style','color:red');
                $("#"+elementId+"-error").removeClass('d-none');
                $("#"+elementId+"-error").html('Please enter your valid '+ elementId);
                $("#"+elementId+"-error").show();
            }else{
                $("#"+elementId+"-error").hide();
            }
           }else{
            $("#"+elementId+"-error").hide();
            
           }
            
            //console.log($(t.attr('id')));
        });
       /* if(name==''){
            $("#name-error").attr('style','color:red');
            $("#name-error").show();

            alert('Please enter your name');
            return false;
        }else if(email==''){
            alert('Please enter your email address');
            return false;
            
        }else if(mobile==''){
            alert('Please enter your mobile number');
            return false;
            
        }else if(password==''){
            alert('Please enter your password');
            return false;
            
        }else{
            let data ={
                name,email,mobile,password
            }
            let result = await fetch("http://127.0.0.1:8000/api/register-user",{
                method:"post",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify(data)
            });
            let response = await result.json();
            console.log(response);
            nagigate("/thanks");
        }*/
        console.log(error);
        if(!error){
            console.log('no any error');
            let data ={
                name,email,mobile,password
            }
           // console.log(data);
            let result = await fetch("http://127.0.0.1:8000/api/register-user",{
                method:"post",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify(data)
            });
            let response = await result.json();
            console.log(response);
            if(response.success!=false){
                nagigate("/thanks");
            }else{
                error=true;
                //console.log(response.errors.email);
                $.each(response.errors,function(i,error){
                    console.log(i);
                    $("#"+i+"-error").attr('style','color:red');
                    $("#"+i+"-error").html(error);
                });
            /* $("#"+elementId+"-error").html('Please enter your '+ elementId);
            $("#"+elementId+"-error").attr('style','color:red');
            $("#"+elementId+"-error").removeClass('d-none');
            $("#"+elementId+"-error").show(); */
            }
            
        }
        
    }
  return (
    <div className="container">
    <div className="reg-wrapper">
        <h2 className="account-h2 u-s-m-b-20">Register</h2>
        
        <h6 className="account-h6 u-s-m-b-30">Registering for this site allows you to access your order status and history.</h6>
        <p id="register-message"></p>
        <form name='register'  id='register' method='post' onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
        <input type="text" value={name} onChange={(e) =>
                                                setName(e.target.value)
                                            } className="form-control input" id="name" placeholder="John" />
        <label htmlFor="name">Name</label>
        <p id='name-error' className='ms-3 d-none'>error</p>
        </div>
        <div className="form-floating mb-3">
        <input type="email" value={email} onChange={(e) =>
                                                setEmail( e.target.value)
                                            } className="form-control input" id="email" placeholder="name@example.com" />
        <label htmlFor="email">Email address</label>
        <p id='email-error' className='ms-3 d-none'>error</p>
        </div>
        <div className="form-floating mb-3">
        <input type="number" value={mobile} onChange={(e) =>
                                                setMobile(e.target.value)
                                            } min={6} className="form-control input" id="mobile" placeholder="1111111111" onWheel={(e) => e.target.blur()} />
        <label htmlFor="mobile">Mobile</label>
        <p id='mobile-error' className='ms-3 d-none'>error</p>
        </div>
    <div className="form-floating">
        <input type="password" value={password} onChange={(e) =>
                                                setPassword(e.target.value)
                                            } className="form-control input" id="password" placeholder="Password" />
        <label htmlFor="password">Password</label>
        <p id='password-error' className='ms-3 d-none'>error</p>
    </div>
    <div className="col-12 mt-2">
        <button type="submit" className="btn btn-primary">Register</button>
    </div>
    </form>
    </div>
  </div>
  )
}
export default Register
