import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { $ } from 'react-jquery-plugin';

export const UpdateUser = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = localStorage.getItem('token');
    const [id,setId] = useState(user.userDetails.id);
    const [name,setName] = useState(user.userDetails.name);
    const [address,setAddress] = useState(user.userDetails.address);
    const [mobile,setMobile] = useState(user.userDetails.mobile);
    const [city,setCity] = useState(user.userDetails.city);
    const [state,setState] = useState(user.userDetails.state);
    const [country,setCountry] = useState(user.userDetails.country);
    const [pincode,setPincode] = useState(user.userDetails.pincode);
    const nagigate = useNavigate();
    const [validated, setValidated] = useState(false);//alert(validated);
    async function handleSubmit(e) {
        e.preventDefault();
        let error = false;
        $(".input").each(function(t,i){
           // $(this).find(':input') //<-- Should return all input elements in that specific form.
           // console.log($(this).find(':input').attr('id'));
           //console.log($(i));
           let elementId = $(i).attr('id');
           if($("#"+elementId).val() == ''){console.log(elementId);
            error=true;
            $("#"+elementId+"-error").html('Please enter your '+ elementId);
            $("#"+elementId+"-error").attr('style','color:red');
            $("#"+elementId+"-error").removeClass('d-none');
            $("#"+elementId+"-error").show();
           }else{
            $("#"+elementId+"-error").hide();
            
           }
            
        });
       
        console.log(error);
        if(!error){
            console.log('no any error');
            let data ={
                id,name,mobile,address,city,state,country,pincode
            }
           // console.log(data);
            let result = await fetch("http://127.0.0.1:8000/api/update-user",{
                method:"post",
                headers:{
                    "Content-Type":"application/json",
                    'Authorization' : 'Bearer '+token,
                },
                body:JSON.stringify(data)
            });
            let response = await result.json();
            console.log(response);
            if(response.success!=false){
                localStorage.setItem('user',JSON.stringify(response));
                nagigate("/account");
            }else{
                error=true;
                //console.log(response.errors.email);
                $.each(response.errors,function(i,error){
                    console.log(i);
                    $("#"+i+"-error").attr('style','color:red');
                    $("#"+i+"-error").removeClass('d-none');
                    $("#"+i+"-error").html(error);
                });
            
            }
            
        }
        
    }
  return (
    <div className="container">
    <div className="reg-wrapper">
        <h2 className="account-h2 u-s-m-b-20">Register</h2>
        
        <h6 className="account-h6 u-s-m-b-30">Registering for this site allows you to access your order status and history.</h6>
        <p id="register-message"></p>
        <form name='register'  id='register' method='post' onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
        <input type="hidden" value={id}  className="form-control input" id="id" placeholder={user.userDetails.id} />
        <input type="text" value={name} onChange={(e) =>
                                                setName(e.target.value)
                                            } className="form-control input" id="name" placeholder={user.userDetails.name} />
        <label htmlFor="name">Name</label>
        <p id='name-error' className='ms-3 d-none'>error</p>
        </div>
        
        <div className="form-floating mb-3">
        <input type="number" value={mobile} onChange={(e) =>
                                                setMobile(e.target.value)
                                            } min={6} className="form-control input" id="mobile" placeholder={user.userDetails.mobile}  onWheel={(e) => e.target.blur()} />
        <label htmlFor="mobile">Mobile</label>
        <p id='mobile-error' className='ms-3 d-none'>error</p>
        </div>
        <div className="form-floating mb-3">
        <input type="text" value={address} onChange={(e) =>
                                                setAddress( e.target.value)
                                            } className="form-control input" id="address" placeholder={user.userDetails.address}  />
        <label htmlFor="address">Address</label>
        <p id='address-error' className='ms-3 d-none'>error</p>
        </div>
    <div className="form-floating">
        <input type="text" value={city} onChange={(e) =>
                                                setCity(e.target.value)
                                            } className="form-control input" id="city" placeholder={user.userDetails.city} />
        <label htmlFor="city">City</label>
        <p id='city-error' className='ms-3 d-none'>error</p>
    </div>
    <div className="form-floating">
        <input type="text" value={state} onChange={(e) =>
                                                setState(e.target.value)
                                            } className="form-control input" id="state" placeholder={user.userDetails.state} />
        <label htmlFor="state">State</label>
        <p id='state-error' className='ms-3 d-none'>error</p>
    </div>
    <div className="form-floating">
        <input type="text" value={country} onChange={(e) =>
                                                setCountry(e.target.value)
                                            } className="form-control input" id="country" placeholder={user.userDetails.country} />
        <label htmlFor="country">Country</label>
        <p id='country-error' className='ms-3 d-none'>error</p>
    </div>
    <div className="form-floating">
        <input type="text" value={pincode} onChange={(e) =>
                                                setPincode(e.target.value)
                                            } className="form-control input" id="pincode" placeholder={user.userDetails.pincode} />
        <label htmlFor="pincode">Pincode</label>
        <p id='pincode-error' className='ms-3 d-none'>error</p>
    </div>
    <div className="col-12 mt-2">
        <button type="submit" className="btn btn-primary">Register</button>
    </div>
    </form>
    </div>
  </div>
  )
}
export default UpdateUser
