import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useWheather } from "../context/WheatherContext";
import Card from "./Card";
import Input from "./CityInput";
import Button from "./Button";
import { PageLoader } from "./page-loader";


const Weather = ()=>{
    const { isLoading, user, loginWithRedirect } = useAuth0();
    const Wheather = useWheather();
    useEffect(()=>{
        Wheather.getCurrentLocation();
    }, []);
    if (isLoading) {
        return (
          <div className="page-layout">
            <PageLoader />
          </div>
        );
      }
      if (!user) {
      loginWithRedirect();
        return null;
      }
    return (
        <div className="container">
            <h1>Wheather</h1>
            <Input />
            
            <Button   onClick={Wheather.fetchData} value="Search"/>
            <Card />
            <Button value="Refresh" onClick={ Wheather.pageRefresh } />
        </div>
    )
};

export default Weather;