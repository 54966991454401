import React,{useEffect, useState,useRef} from 'react';
import axios from 'axios';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

export const Shop = () => {
    const [menu,setMenu] = useState([]);
    const fetchData = ()=>{
        return axios.get("http://127.0.0.1:8000/api/get-menu")
        .then((response)=>setMenu(response.data['categories']));
    }
    const menuRef = useRef(menu);
    useEffect(()=>{
        fetchData();
    },[menuRef]);
    const [searchParams,setSearchParams] = useSearchParams();
    //console.log(useSearchParams())
  return (
    <div className="container ">
        <div className="row g-4 py-5">
        <ul className="navbar-nav">
        <li className="nav-item dropdown">
        {
            menu.map((menuObj)=>{
                return <div key={menuObj.id}>
                    
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">{menuObj.name}</a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                {
                    menuObj.categories.map((catObj)=>{
                        return <Link key={catObj.id} className="dropdown-item" to={'/listing?url='+catObj.url}><li>{catObj.category_name}</li></Link>
                    })
                    
                }
                </ul>
                </div>
            })
        
        }
        </li>
        </ul>
        </div>
        </div>
  )
}
export default Shop
