// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
// import { getMessaging } from "firebase/messaging/sw";
const firebaseConfig = {
    apiKey: "AIzaSyDFzB1iOMsKLWulfir-wJsXrwmj74XIYuA",
    authDomain: "react-ecom-d7790.firebaseapp.com",
    projectId: "react-ecom-d7790",
    storageBucket: "react-ecom-d7790.appspot.com",
    messagingSenderId: "629739205784",
    appId: "1:629739205784:web:a9707a7151b94b39608ced",
    measurementId: "G-NRQMGMKFWP"
  };
  

// Initialize Firebase
//export const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
//export const messaging = getMessaging(app);


export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);