import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { WheatherProvider } from './context/WheatherContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider domain="dev-yn47nap3q0eyjn5e.us.auth0.com"
    clientId="UiV3q1Vv2sjdwA6R2iw0tgQvjJXx7ilm"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}>
    <WheatherProvider>
    <App />
    </WheatherProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
