import React, { useState} from "react";
import { useNavigate } from "react-router-dom";

const HomeLiveRoom = ()=>{
    const [liveRoomCode, setLiveRoomCode] = useState('');
    const navigate = useNavigate();
    const handleFormSubmit = (ev)=>{
        ev.preventDefault();
    navigate(`/liveroom/${liveRoomCode}`);
    }
    return <div className="container">
                <div className="reg-wrapper">
                <h1 className="account-h2 u-s-m-b-20">Room Code</h1>
                <form onSubmit={handleFormSubmit}>
                <input type="text" value={liveRoomCode} onChange={(e) =>
                                                    setLiveRoomCode(e.target.value)
                                                } className="form-control input" id="liveroomcode" placeholder="Enter room code" />
                                                

                <div className="col-12 mt-2">
                <button type="submit"  className="btn btn-primary">Join</button>
                
                </div>
                </form>
                </div>
                </div>
}

export default HomeLiveRoom